import React from "react";
import { IntlProvider } from "gatsby-plugin-intl";
import ContextProvider from "./src/components/context/contextProvider";

export const wrapPageElement = ({ element }) => {
  return (
    <IntlProvider>
      <ContextProvider>
        <div className="absolute top-20 right-2 z-20 md:right-5"></div>
        {element}
      </ContextProvider>
    </IntlProvider>
  );
};

export const onRenderBody = ({ setHeadComponents, setPostBodyComponents }) => {
  // Ezoicスクリプトを<head>内に追加
  setHeadComponents([
    <script
      key="ezoic-ads"
      async
      src="//www.ezojs.com/ezoic/sa.min.js"
    ></script>,
    <script
      key="gatekeeper-consent"
      src="https://the.gatekeeperconsent.com/cmp.min.js"
      data-cfasync="false"
    ></script>,
  ]);

  // Ezoic広告用のプレースホルダーと初期化スクリプトを<body>内に追加
  setPostBodyComponents([
    <script
      key="ezstandalone-init"
      dangerouslySetInnerHTML={{
        __html: `
          window.ezstandalone = window.ezstandalone || {};
          ezstandalone.cmd = ezstandalone.cmd || [];
        `,
      }}
    ></script>,
    <div key="ezoic-ad-placeholder" id="ezoic-pub-ad-placeholder-101"></div>,
    <script
      key="ezstandalone-showAds"
      dangerouslySetInnerHTML={{
        __html: `
          ezstandalone.cmd.push(function() {
            ezstandalone.showAds(101);
          });
        `,
      }}
    ></script>,
  ]);
};
